"use client";
import Image from "next/image";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import {
  hasArabicLetter,
  hasLetter,
  hasSpecialCharacter,
} from "@/utils/functions.client";
import { useSelectLocale } from "@/hooks";
import "./index.css";
import { DIGITS } from "@/utils/constants";
import "@/app/[lang]/styles/style.css";

export default function PhonePrefixSelect({
  phonePrefixes = [],
  countryPrefix = DIGITS?.DIGIT_964?.toString(),
  setPrefixCode = function () {},
  tabIndex = DIGITS?.ZERO,
  lang = "en",
}) {
  const locale = useSelectLocale();
  const [showOptions, setShowOptions] = useState(false);
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);
  const [searchPrefix, setSearchPrefix] = useState("");
  const currentCountryCode = phonePrefixes.find(
    (countryCode) => countryCode.value === countryPrefix
  );
  const prefixRef = useRef(null);
  const optionRefs = useRef(phonePrefixes.map(() => React.createRef()));
  const filteredOptions = [];
  const optionsList = [];

  useEffect(() => {
    // This effect ensures the highlighted option is scrolled into view when changed
    if (
      showOptions &&
      currentOptionIndex >= DIGITS?.ZERO &&
      optionRefs.current[currentOptionIndex]?.current
    ) {
      optionRefs.current[currentOptionIndex].current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    if (showOptions) prefixRef?.current?.focus();
  }, [currentOptionIndex, showOptions]);

  if (Array.isArray(phonePrefixes) && phonePrefixes.length > DIGITS?.ZERO) {
    phonePrefixes.map(({ value, image, code }) => {
      if (value.includes(searchPrefix)) {
        filteredOptions.push({
          value,
          image,
          code,
        });
      }
    });
    const selectedIndex =
      currentOptionIndex > filteredOptions.length - DIGITS?.ONE
        ? DIGITS?.ZERO
        : currentOptionIndex;
    filteredOptions.map(({ value, image, code }, index) => {
      optionsList.push(
        <div
          ref={optionRefs.current[index]}
          key={value + code + index}
          data-selectable=""
          data-value={value}
          className={
            "digits-iq" +
            (index === selectedIndex
              ? " active phone-prefix-active-option"
              : "") +
            (["ar", "kd"].includes(locale) ? "" : " reqular-iq")
          }
          onMouseOver={() => {
            setPrefixCode(value);
            setCurrentOptionIndex(index);
          }}
        >
          <Image
            src={image}
            className={"phone-prefix-flag flag flag-" + (code || "")}
            alt="flag"
            width={DIGITS?.DIGIT_28}
            height={DIGITS?.DIGIT_18}
          />{" "}
          +{value}
        </div>
      );
    });
  }

  return (
    <div
      className={"selectize-control countrySelectize single"}
      onClick={() => {
        setShowOptions((state) => !state);
      }}
      dir="ltr"
    >
      <div
        className={
          "selectize-input items full has-options has-items" +
          (showOptions ? " focus input-active dropdown-active" : "")
        }
        onClick={(e) => {}}
      >
        <div
          className={
            "digits-iq regular-iq" + (!showOptions ? "d-block" : "d-none")
          }
          data-value={currentCountryCode?.value || ""}
        >
          <Image
            src={currentCountryCode?.image || "/"}
            className={
              "phone-prefix-flag flag flag-" + (currentCountryCode?.code || "")
            }
            alt="flag"
            width={DIGITS?.DIGIT_28}
            height={DIGITS?.DIGIT_18}
          />{" "}
          +{currentCountryCode?.value || ""}
        </div>
        <input
          className={
            "digits-iq reqular-iq " +
            (showOptions
              ? "phone-prefix-show-options"
              : "phone-prefix-unshow-options")
          }
          ref={showOptions ? prefixRef : null}
          type="select-one"
          autoComplete="new-password"
          tabIndex={tabIndex || DIGITS?.ZERO}
          value={"+" + searchPrefix || ""}
          onChange={(e) => {
            let prefix = e?.target?.value || "";

            if (
              hasLetter(prefix) ||
              hasSpecialCharacter(prefix) ||
              hasArabicLetter
            )
              return;

            if (prefix.startsWith("+")) prefix = prefix.slice(DIGITS?.ONE);
            setSearchPrefix(prefix);
            setCurrentOptionIndex(DIGITS?.ZERO);
          }}
          onKeyDown={(e) => {
            const key = e?.key?.toLowerCase() || "";
            if (key === "arrowup") {
              if (currentOptionIndex > DIGITS?.ZERO)
                setCurrentOptionIndex(currentOptionIndex - DIGITS?.ONE);
            } else if (key === "arrowdown") {
              if (currentOptionIndex < optionsList?.length - DIGITS?.ONE)
                setCurrentOptionIndex(currentOptionIndex + DIGITS?.ONE);
            } else if (key === "enter") {
              e.preventDefault();
              if (filteredOptions[currentOptionIndex]?.value)
                setPrefixCode(filteredOptions[currentOptionIndex].value);
              setShowOptions(false);
            } else if (key === "escape") setShowOptions(false);
          }}
          onFocus={(e) => {
            if (!showOptions) setShowOptions(true);
          }}
          onBlur={(e) => setShowOptions(false)}
          readOnly={showOptions ? false : true}
        />
      </div>
      <div
        className={
          "selectize-dropdown single countrySelectize phone-prefix-container " +
          (showOptions ? "d-block" : "d-none")
        }
      >
        <div className="selectize-dropdown-content">{optionsList}</div>
      </div>
    </div>
  );
}

PhonePrefixSelect.propTypes = {
  /**
   * Phone Prefixes
   */
  phonePrefixes: PropTypes.array.isRequired,
  /**
   * Selected prefix value
   */
  countryPrefix: PropTypes.string.isRequired,
  /**
   * Function to set new selected prefix
   */
  setPrefixCode: PropTypes.func.isRequired,
  /**
   * Tab index
   */
  tabIndex: PropTypes.number,
  /**
   * Lang
   */
  lang: PropTypes.string.isRequired,
};
